
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'LoadCardInitialAndFinalStop',
  props: {
    pickupCity: { type: String, required: true, default: '' },
    pickupCt: { type: String, required: true, default: '' },
    pickupIsoCode: { type: String, required: true, default: '' },
    pickupDate: { type: String, required: true, default: '' },
    deliveryCity: { type: String, required: true, default: '' },
    deliveryCt: { type: String, required: true, default: '' },
    deliveryIsoCode: { type: String, required: true, default: '' },
    deliveryDate: { type: String, required: true, default: '' },
    dateTimeEta: { type: String, required: false, default: '' },
  },
  setup (props) {
    const store = useStore();
    const translate = useI18n();
    const formattedDeliveryDate = computed(() => {
    const localValue = translate.locale.value.split('-')[0];
      return {
        date: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
          ? moment(props.deliveryDate).locale(localValue).format('MMM DD, YYYY')
          : moment(props.deliveryDate).locale(localValue).format('DD MMM, YYYY'),
        hour: '',
      }
    })

    const formattedPickupDate = computed(() => {
      const localValue = translate.locale.value.split('-')[0];
      return {
        date: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
          ? moment(props.pickupDate).locale(localValue).format('MMM DD, YYYY')
          : moment(props.pickupDate).locale(localValue).format('DD MMM, YYYY'),
        hour: '',
      }
    })

    const formattedDateTimeEta = computed(() => {
      if (props.dateTimeEta === '') return '';
      const localValue = translate.locale.value.split('-')[0];
      return {
        date: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
          ? moment(props.dateTimeEta).locale(localValue).format('MMM DD, YYYY')
          : moment(props.dateTimeEta).locale(localValue).format('DD MMM, YYYY'),
        hour: '',
      }
    })

    return {
      formattedDeliveryDate,
      formattedPickupDate,
      formattedDateTimeEta
    }
  }
})
